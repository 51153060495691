import React from "react";
import { Modal, Button, Table } from "semantic-ui-react";
import { getTenantFromURL } from "../../../../../BytebeamClient";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import { DashboardTitle } from "../../../Dashboards/ListDashboards";
import { User } from "../../../../../util";
import { TableContainer } from "../../../Dashboards/Panel/util";

type ShowDeviceDashboardModalProps = {
  user: User;
  deviceId: number;
  isOpen: boolean;
  dashboards: any[];
  close: () => any;
};

type ShowDeviceDashboardModalState = {
  loading: boolean;
  errorOccured: boolean;
};

export class ShowDeviceDashboardModal extends React.Component<
  ShowDeviceDashboardModalProps,
  ShowDeviceDashboardModalState
> {
  constructor(props: ShowDeviceDashboardModalProps) {
    super(props);

    this.state = {
      loading: false,
      errorOccured: false,
    };
  }

  render() {
    const { deviceId, isOpen, close } = this.props;
    const currentTenant = getTenantFromURL();

    return (
      <Modal
        centered={true}
        open={isOpen}
        onClose={close}
        size="large"
        className="dark"
      >
        <Modal.Header>Select device dashboard</Modal.Header>
        <Modal.Content>
          <TableContainer style={{ height: "75vh", paddingTop: "0px" }}>
            <div className="tableContentContainer" style={{ padding: "0px" }}>
              <Table celled selectable fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>ID</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Title</Table.HeaderCell>
                    <Table.HeaderCell width={7}>Info</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.dashboards.length !== 0 ? (
                    this.props.dashboards
                      .filter((dashboard) => {
                        const userIdString =
                          "user:" + this.props.user.id || "xx";
                        const roleString =
                          "role:" + this.props.user.role.id || "xx";

                        const hideFromAdmins =
                          dashboard?.config?.dashboardMeta?.hideFromAdmins ??
                          false;
                        const owners =
                          dashboard?.config?.dashboardMeta?.owners ?? [];
                        const viewers =
                          dashboard?.config?.dashboardMeta?.viewers ?? [];

                        return (
                          dashboard.config.dashboardMeta?.type === "device" &&
                          (hideFromAdmins
                            ? owners.includes(userIdString) ||
                              owners.includes(roleString) ||
                              viewers.includes(userIdString) ||
                              viewers.includes(roleString)
                            : true)
                        );
                      })
                      .map((dashboard) => {
                        return (
                          <Table.Row
                            key={dashboard.id}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              window.open(
                                `/projects/${currentTenant}/dashboards/${dashboard.id}?id=${deviceId}`,
                                "_blank"
                              );
                            }}
                          >
                            <Table.Cell>{dashboard.id}</Table.Cell>

                            <Table.Cell>
                              <DashboardTitle>
                                {dashboard.config.dashboardMeta?.title ?? ""}
                              </DashboardTitle>
                            </Table.Cell>
                            <Table.Cell>
                              {dashboard.config.dashboardMeta?.info || "--"}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={"3"}>
                        <ErrorMessage message={"No Dashboards Found!"} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </div>
          </TableContainer>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => close()}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
