import React from "react";

import { PanelContainer } from "./PanelContainer";

import {
  PanelMetaData,
  PanelEditComponent,
  PanelDef,
  PartialMetaData,
} from "./PanelDef";
import { TableInfo, fetchPanelData } from "../../../../BytebeamClient";
import { ReplayState } from "../DashboardHeader";
import { DashboardsInfo } from "../ViewDashboard";
import { Settings } from "../../../../util";
import { FetchParams } from "./util";
import styled from "styled-components";
import { DashboardMeta, DashboardType } from "../EditDashboardModal";
import { breakpoints } from "../../../common/breakpoints";

const PanelEditContainer = styled.div`
  background: ${({ theme }) => theme.colors["background-color"]};
  height: 100%;
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: top;

  @media (max-width: ${breakpoints.lg}px) {
    flex-direction: column;
    padding: 12px;
  }
`;

const PanelEditDivLeft = styled.div`
  position: sticky;
  top: 84px;
  background: ${({ theme }) => theme.colors["background-color"]};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: top;
  z-index: 15 !important;

  @media (max-width: ${breakpoints.lg}px) {
    position: relative;
    top: 0px;
  }
`;

const PanelEditDivRight = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors["background-color"]};
  height: 100%;
  width: 100%;
`;

const DividerDiv = styled.div`
  border-left: 1px solid #464646;
  height: 500px;
  margin: 0px 16px;

  @media (max-width: ${breakpoints.lg}px) {
    height: 0px;
    border-top: 1px solid #464646;
    width: 100%;
    margin: 16px 0px;
  }
`;

const PreviewDiv = styled("div")<{
  aspectratiowidth: number;
  aspectRatioHeight: number;
  elementWidth: number;
}>`
  width: ${(props) => {
    return 100 + "%";
  }};
  height: ${(props) => {
    return (
      (props.aspectRatioHeight / props.aspectratiowidth) * props.elementWidth +
      "px"
    );
  }};

  .fullscreen {
    width: 100%;
    height: 100%;
  }
`;

type EditPanelWidgetProps<MetaDataType extends PanelMetaData, DataType> = {
  panelDef: PanelDef<MetaDataType, DataType>;
  panelMeta: MetaDataType;
  fetchParams: FetchParams;
  tables: TableInfo;
  settings: Settings;
  dashboards: DashboardsInfo[];
  dashboardMeta: DashboardMeta;
};

type EditPanelWidgetState<MetaDataType extends PanelMetaData, DataType> = {
  panelMeta?: MetaDataType;
  data?: DataType;
  error?: boolean;
  errorMessage?: string;
  fullPage: boolean;
  fullPagePanelId: string;
};

export class EditPanelWidget<
  MetaDataType extends PanelMetaData,
  DataType,
> extends React.Component<
  EditPanelWidgetProps<MetaDataType, DataType>,
  EditPanelWidgetState<MetaDataType, DataType>
> {
  cancelled: boolean = false;
  editMetaRef = React.createRef<PanelEditComponent<MetaDataType>>();
  previewDivRef = React.createRef<HTMLDivElement>();
  abortController = new AbortController();

  constructor(props) {
    super(props);

    this.state = {
      panelMeta: props.panelMeta,
      fullPage: false,
      fullPagePanelId: "",
    };
  }

  async refreshPanel() {
    const editMeta = this.editMetaRef.current;

    if (editMeta && this.props.panelDef) {
      const { meta, complete } = editMeta.getPanelMeta("refresh");

      // Assigning ["id"] to groupBys in panelMeta for fleet dashboard to get data for individual device
      if (this.props.dashboardMeta?.type === DashboardType.FleetDashboard) {
        meta.groupBys = ["id"];
      }

      if (complete) {
        if (meta.type === "aggregate_value") {
          meta["is_panel"] = true;
        }
        try {
          const data = await fetchPanelData(
            [meta],
            this.props.fetchParams,
            this.abortController.signal,
            this.props.dashboardMeta?.type || "device"
          );

          if (data?.length) {
            if (data[0]?.error) {
              this.setState({
                panelMeta: meta,
                error: true,
                errorMessage: data[0]?.error,
              });
            }
            this.setState({ panelMeta: meta, data: data[0]?.data });
          }

          return true;
        } catch (err) {
          console.error("Error in fetching panel data.");
          if (err) {
            this.setState({ panelMeta: meta, error: true });
          }
          console.error(err);
        }
      } else {
        this.setState({ panelMeta: meta });

        return false;
      }
    }

    return false;
  }

  componentDidMount() {
    // To scroll to top of the screen
    window.scrollTo(0, 0);

    const refreshPanel = async () => {
      const refreshed = await this.refreshPanel();

      if (!this.cancelled) {
        const timeout = refreshed ? 1000 : 300;
        setTimeout(refreshPanel, timeout);
      }
    };

    setTimeout(refreshPanel);
  }

  componentWillUnmount() {
    this.cancelled = true;
    this.abortController.abort();
  }

  getPanelMeta(type): PartialMetaData<MetaDataType> | undefined {
    if (this.editMetaRef.current) {
      return this.editMetaRef.current.getPanelMeta(type);
    }
  }

  onToggleFullPage(panelId: string) {
    const rootElement = document.getElementById("root");

    if (this.state.fullPage) {
      rootElement?.classList.remove("fullpage-mode");
    } else {
      rootElement?.classList.add("fullpage-mode");
    }

    this.setState({ fullPage: !this.state.fullPage, fullPagePanelId: panelId });
  }

  generateViewComponent() {
    return (
      <PreviewDiv
        ref={this.previewDivRef}
        elementWidth={
          this.previewDivRef.current
            ? this.previewDivRef.current.offsetWidth
            : 500
        }
        aspectratiowidth={
          this.props.panelDef.previewAspectRatio.width
            ? this.props.panelDef.previewAspectRatio.width
            : 500
        }
        aspectRatioHeight={
          this.props.panelDef.previewAspectRatio.height
            ? this.props.panelDef.previewAspectRatio.height
            : 300
        }
      >
        {this.state.panelMeta ? (
          <PanelContainer
            timeRange={this.props.fetchParams.timeRange}
            data={this.state.data}
            error={this.state.error}
            errorMessage={this.state.errorMessage}
            panelMeta={this.state.panelMeta}
            panelDef={this.props.panelDef}
            settings={this.props.settings}
            editable={false}
            replayTimestamp={new Date().valueOf()}
            replayStep={0}
            replayState={ReplayState.ReplayStopped}
            fetchParams={this.props.fetchParams}
            dashboards={this.props.dashboards}
            dashboardMeta={this.props.dashboardMeta}
            fullPage={this.state.fullPage}
            onToggleFullPage={this.onToggleFullPage.bind(this)}
            tables={this.props.tables}
            editMode={false}
          />
        ) : (
          "No data"
        )}
      </PreviewDiv>
    );
  }

  render() {
    return (
      <PanelEditContainer>
        <PanelEditDivLeft className="view-panel">
          {this.generateViewComponent()}
        </PanelEditDivLeft>

        <DividerDiv />

        <PanelEditDivRight>
          <this.props.panelDef.EditComponent
            ref={this.editMetaRef}
            tables={this.props.tables}
            panelMeta={this.props.panelMeta}
            dashboards={this.props.dashboards}
            dashboardType={this.props.dashboardMeta?.type ?? "device"}
          />
        </PanelEditDivRight>
      </PanelEditContainer>
    );
  }
}
