import React, { useState, useEffect } from "react";
import { capitalizeFirstLetter } from "../../util";
import { ActionType } from "../../../../util";
import styled from "styled-components";
import { Dropdown, Icon } from "semantic-ui-react";

type ActionButtonsProps = {
  readonly allSelected: boolean;
  readonly selectedDevices: Map<string | number, string>;
  readonly openUploadScriptModal: (...args: any[]) => any;
  readonly openConfirmationModal: (...args: any[]) => any;
  readonly openUpdateFirmwareModal: (...args: any[]) => any;
  readonly openUpdateConfigModal: (...args: any[]) => any;
  readonly openUpdateGeofenceModal: (...args: any[]) => any;
  readonly openSendFileModal: (...args: any[]) => any;
  readonly allowedActions: "all" | string[];
  readonly actionButtonsDisabled: boolean;
  readonly actionTypes: ActionType[];
};

export const ActionButton = styled.button`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 8px;
  white-space: nowrap;
  background: ${(props) =>
    props?.style?.background
      ? props?.style?.background
      : props.theme.colors["action_button-background"]};
  margin-right: ${(props) =>
    props?.style?.marginRight ? props?.style?.marginRight : "8px"};
  padding: ${(props) =>
    props?.style?.padding ? props?.style?.padding : "11px 21px"};
  font-weight: 700;
  border-radius: ${(props) =>
    props?.style?.borderRadius ? props?.style?.borderRadius : "10px"};
  color: ${(props) =>
    props?.style?.color
      ? props?.style?.color
      : props.theme.colors["action_button-text"]};
  opacity: 1;
  border: ${(props) =>
    props?.style?.border
      ? props?.style?.border
      : props.theme.colors["container-border"]};
  cursor: pointer;
  transition: opacity 0.1s ease;
  box-shadow: ${(props) =>
    props?.style?.boxShadow
      ? props?.style?.boxShadow
      : props.theme.colors["action_button-box_shadow"]};
  &:disabled {
    cursor: not-allowed;
    background: ${(props) =>
      props?.style?.background
        ? props?.style?.background
        : props.theme.colors["action_button-background-disable"]};
    color: ${(props) =>
      props?.style?.color
        ? props?.style?.color
        : props.theme.colors["action_button-text-disable"]};
    box-shadow: ${(props) =>
      props?.style?.boxShadow
        ? props?.style?.boxShadow
        : props.theme.colors["action_button-box_shadow-disable"]};
  }
`;

const ActionDropdownButton = styled(Dropdown)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: 16px;
  background: ${(props) =>
    props?.disable
      ? props.theme.colors["action_button-background-disable"]
      : props.theme.colors["action_button-background"]} !important;
  color: ${(props) =>
    props?.disable
      ? props.theme.colors["action_button-text-disable"]
      : props.theme.colors["action_button-text"]} !important;
  border: ${(props) => props.theme.colors["container-border"]} !important;
  border-radius: 12px !important;
  box-shadow: ${(props) =>
    props?.disable
      ? props.theme.colors["action_button-box_shadow-disable"]
      : props.theme.colors["action_button-box_shadow"]};
`;

export function ActionButtons(props: ActionButtonsProps) {
  const {
    allowedActions,
    actionButtonsDisabled,
    selectedDevices,
    allSelected,
    openUpdateFirmwareModal,
    openUpdateConfigModal,
    openUpdateGeofenceModal,
    openSendFileModal,
    openUploadScriptModal,
    openConfirmationModal,
    actionTypes,
  } = props;

  const [actionTypesToRender, setActionTypesToRender] = useState<ActionType[]>(
    []
  );

  useEffect(() => {
    const getActionTypesToRender = async () => {
      try {
        const actionTypesToRender = actionTypes.filter(
          (actionType: ActionType) => {
            return (
              // update_config, update_firmware and launch_shell are default action types
              actionType.type !== "update_config" &&
              actionType.type !== "update_firmware" &&
              actionType.type !== "launch_shell" &&
              allowedActions.indexOf(actionType.type) !== -1
            );
          }
        );

        setActionTypesToRender(actionTypesToRender);
      } catch (e) {
        console.log(e);
      }
    };

    getActionTypesToRender();
  }, [actionTypes, allowedActions]);

  const isUpdateFirmware_Allowed = allowedActions.includes("update_firmware");
  const isUpdateConfig_Allowed = allowedActions.includes("update_config");
  const isUpdateGeofence_Allowed = allowedActions.includes("update_geofence");
  const isSendFile_Allowed =
    allowedActions.includes("send_file") &&
    actionTypesToRender.some(
      (actionType: ActionType) => actionType.type === "send_file"
    );
  const isSendScript_Allowed =
    allowedActions.includes("send_script") &&
    actionTypesToRender.some(
      (actionType: ActionType) => actionType.type === "send_script"
    );

  const isActionButtonsDisable =
    actionButtonsDisabled || (selectedDevices.size === 0 && !allSelected);

  return (
    <div className="action-buttons">
      {isUpdateFirmware_Allowed && (
        <ActionButton
          disabled={isActionButtonsDisable}
          onClick={openUpdateFirmwareModal}
          style={{
            width: "184px",
          }}
        >
          <Icon name="download" /> <span>Update Firmware</span>
        </ActionButton>
      )}

      {isUpdateConfig_Allowed && (
        <ActionButton
          disabled={isActionButtonsDisable}
          onClick={openUpdateConfigModal}
          style={{
            width: "184px",
          }}
        >
          <Icon name="configure" /> <span>Update Config</span>
        </ActionButton>
      )}

      {actionTypesToRender.length ? (
        <ActionDropdownButton
          scrolling
          disable={isActionButtonsDisable}
          icon="angle double right"
        >
          <Dropdown.Menu
            style={{ zIndex: 97 }}
            className={`${isActionButtonsDisable ? "cursor-not-allowed" : ""}`}
          >
            {/* update_geofence is an default action type */}
            {isUpdateGeofence_Allowed && (
              <Dropdown.Item
                key={"update_geofence"}
                icon={"map outline"}
                content={"Update Geofence"}
                disabled={isActionButtonsDisable}
                onClick={openUpdateGeofenceModal}
              />
            )}
            {/* send_file is not an default action type but it's
                functionality is defined in backend */}
            {isSendFile_Allowed && (
              <Dropdown.Item
                key={"send_file"}
                icon={"send"}
                content={"Send File"}
                disabled={isActionButtonsDisable}
                onClick={openSendFileModal}
              />
            )}
            {/* send_script is not an default action type but it's
                functionality is defined in backend */}
            {isSendScript_Allowed && (
              <Dropdown.Item
                key={"send_script"}
                icon={"file"}
                content={"Send Script"}
                disabled={isActionButtonsDisable}
                onClick={openUploadScriptModal}
              />
            )}
            {/* All Actions Other than default  */}
            {actionTypesToRender
              .filter(
                (actionType: ActionType) =>
                  // send_file and send_script are filtered out since they are inbuilt action types and defined above
                  ![
                    "update_geofence",
                    "send_file",
                    "send_script",
                    "cancel_action",
                    "retry_action",
                  ].includes(actionType.type)
              )
              .map((actionType: ActionType) => (
                <Dropdown.Item
                  key={actionType.type}
                  icon={actionType.icon}
                  content={capitalizeFirstLetter(actionType.type)}
                  disabled={isActionButtonsDisable}
                  onClick={() => openConfirmationModal(actionType)}
                />
              ))}
          </Dropdown.Menu>
        </ActionDropdownButton>
      ) : null}
    </div>
  );
}
